import React from 'react';
import ContentLoader, { type IContentLoaderProps } from 'react-content-loader';
import { Rect, Circle } from 'react-content-loader/native';

const ChatLoader: React.FC<IContentLoaderProps> = (props) => (
  <ContentLoader viewBox="0 0 400 250" {...props}>
    <Rect x="270" y="15" rx="4" ry="4" width="120" height="20" />

    <Circle cx="20" cy="121" r="8" />
    <Rect x="30" y="50" rx="4" ry="4" width="120" height="80" />
    {/* 30% of 400 is 120 */}

    {/* Positioned at 280 (70% of 400) to be on the right */}

    <Rect x="270" y="140" rx="4" ry="4" width="120" height="20" />
    {/* Positioned at 280 (70% of 400) to be on the right */}

    <Circle cx="20" cy="222" r="8" />
    <Rect x="30" y="170" rx="4" ry="4" width="120" height="60" />
  </ContentLoader>
);

export default ChatLoader;
