import { StyleSheet, type ViewStyle, type TextStyle } from 'react-native';

export interface StyleCustomization {
  backgroundColor?: string;
  chatHeight?: string | number;
  primaryColor?: string;
  assistantColor?: string;
  primaryTextColor?: string;
  assistantTextColor?: string;
  dayLabelColor?: string;
  sendMessageStyles?: ViewStyle;
  inputFieldStyles?: ViewStyle;
  spinnerStyles?: ViewStyle;
  useIcon?: boolean;
  messagesAdditionalStyles?: ViewStyle;
  botIcon?: React.ReactNode;
  useBotIcon?: boolean;
  botIconSize?: number;
  iconVariant?: 'light' | 'solid';
  sendIconStyles?: ViewStyle;
  messageMaxWidth?: number;
  messagesContainerStyle?: ViewStyle;
  messagesAndFormContainerStyle?: ViewStyle;
  debugStyle?: ViewStyle;
  centerChat?: boolean;
}

export const useStyles = (styleCustomization?: StyleCustomization) => {
  const { backgroundColor = 'white', chatHeight = '100%' } =
    styleCustomization || {};

  return StyleSheet.create({
    globalContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: '100%',
      flex: 1,
      maxHeight: chatHeight,
    } as ViewStyle,
    messagesAndFormContainer: {
      borderRadius: 16,
      flex: 2,
      backgroundColor,
    } as ViewStyle,
    messagesContainer: {
      borderRadius: 16,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      height: '100%',
      boxShadow: '1px 1px 8px 0 rgba(0, 0, 0, 0.16)',
    } as ViewStyle,
    chatLoader: {
      flex: 1,
      justifyContent: 'flex-end',
      flexDirection: 'column',
      display: 'flex',
    } as ViewStyle,
    errorText: {
      color: 'red',
      fontWeight: 'bold',
      margin: 16,
    } as TextStyle,
    debugContainer: {
      borderRadius: 16,
      boxShadow: '1px 1px 8px 0 rgba(0, 0, 0, 0.16)',
      padding: 32,
      flex: 1,
      marginLeft: 32,
      minWidth: 320,
    } as ViewStyle,
    closeButton: {
      position: 'absolute',
      top: 8,
      right: 8,
      backgroundColor: 'lightgrey',
      borderRadius: 16,
      padding: 8,
      zIndex: 1,
    } as ViewStyle,
    closeButtonText: {
      fontWeight: 'bold',
      color: 'black',
    } as TextStyle,
  });
};
