import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { TamaguiProvider } from 'tamagui';
import tamaguiConfig from './tamagui.config';
import { MessagesList } from './components/MessageList';
import { SendMessageForm } from './components/SendMessageForm';
import { Debug } from './components/Debug';
import ChatLoader from './components/ChatLoader';
import { useConversation } from './hooks/useConversation';
import { useStyles, type StyleCustomization } from './hooks/useStyles';
import { useWebSocket } from './hooks/useWebSocket';
import { useAuth } from './hooks/useAuth';
import { type MessageInterface } from './interfaces/Message.interface';
// Updated type definitions
interface ConversationAiProps {
  wsUrl?: string;
  authToken?: string;
  inverted?: boolean;
  scrollToMessageTimestamp?: string;
  apiBaseUrl: string;
  styleCustomization?: StyleCustomization;
  translations?: { messageInputPlaceholder: string };
  onError?: (error: string) => void;
  showDebug?: boolean;
  anonymous?: boolean;
  welcomeMessages?: { language: string; label: string }[];
  organizationId?: string;
  host?: string;
  requesterId?: string;
}

interface MessagesListProps {
  messages: MessageInterface[];
  inverted: boolean;
  primaryColor: string;
  assistantColor: string;
  primaryTextColor: string;
  assistantTextColor: string;
  dayLabelColor: string;
  debugClicked: (message: MessageInterface) => void;
  showDebug: boolean;
  messagesAdditionalStyles?: object;
  botIcon?: React.ReactNode;
  useBotIcon: boolean;
  messageMaxWidth?: number;
  botIconSize?: number;
}

interface SendMessageFormProps {
  apiBaseUrl: string;
  audioUrl: string;
  preferencesUrl: string;
  style: {
    sendMessageStyles?: object;
    inputFieldStyles?: object;
    sendIconStyles?: object;
    spinnerStyles?: object;
    color?: string;
    iconVariant?: 'light' | 'solid';
  };
  isLoading: boolean;
  translations?: { messageInputPlaceholder: string };
  audioSent: () => void;
  sendMessage: (text: string) => void;
}

const defaultStyleCustomization: StyleCustomization = {
  backgroundColor: 'white',
  chatHeight: '100%',
  primaryColor: 'rgb(91, 103, 212)',
  assistantColor: 'rgb(240, 240, 240)',
  primaryTextColor: 'white',
  assistantTextColor: 'black',
  dayLabelColor: 'rgb(178, 178, 178)',
  useBotIcon: true,
  botIconSize: 36,
  centerChat: false,
};

const ConversationAi: React.FC<ConversationAiProps> = ({
  wsUrl,
  authToken,
  inverted = false,
  scrollToMessageTimestamp,
  apiBaseUrl,
  styleCustomization = defaultStyleCustomization,
  translations,
  onError,
  showDebug = false,
  anonymous = false,
  welcomeMessages,
  organizationId,
  host,
  requesterId,
}) => {
  const [currentLanguage, setCurrentLanguage] = useState<LanguageType | undefined>(undefined);
  const {
    messages,
    recevingNewMessage,
    setRecevingNewMessage,
    messagesLoaded,
    handleNewMessage,
    sendMessage: sendConversationMessage,
  } = useConversation({
    apiBaseUrl,
    authToken,
    organizationId,
    anonymous,
    language: currentLanguage,
    welcomeMessages,
    onError: onError as ((error: string) => void) | undefined,
    requesterId,
  });
  const {
    updateHost,
    headers,
    updateAnonymousSession,
    updateAuthToken,
    anonymousSessionId,
    authLoaded,
  } = useAuth();

  const handleLanguageChange = useCallback((newLanguage: LanguageType) => {
    setCurrentLanguage(newLanguage);
  }, []);

  const { connectWebSocket, onNewMessage, sendMessage, isConnected } = wsUrl
    ? useWebSocket({
      updateAnonymousSession,
    })
    : {
      connectWebSocket: null,
      onNewMessage: null,
      sendMessage: null,
      isConnected: false,
    };

  const mergedStyleCustomization = {
    ...defaultStyleCustomization,
    ...styleCustomization,
  };

  const styles = useStyles(mergedStyleCustomization);
  const messagesListRef = useRef<MessagesList>(null);
  const [selectedMessage, setSelectedMessage] =
    useState<MessageInterface | null>(null);
  const lastScrollTime = useRef(0);

  useEffect(() => {
    if (authLoaded && wsUrl && connectWebSocket && (host || authToken)) {
      connectWebSocket({ wsUrl, host, authToken, anonymousSessionId });
    }
  }, [anonymousSessionId, host, authToken, authLoaded]);

  useEffect(() => {
    if (host) {
      updateHost(host);
    }
  }, [host]);

  useEffect(() => {
    if (authToken) {
      updateAuthToken(authToken);
    }
  }, [authToken]);

  useEffect(() => {
    if (messagesLoaded && messages.length > 0) {
      if (scrollToMessageTimestamp) {
        const messageIndex = messages.findIndex(
          (msg) =>
            msg.createdAt.getTime() ===
            new Date(scrollToMessageTimestamp).getTime()
        );
        if (messageIndex !== -1) {
          messagesListRef.current?.scrollToIndex(messageIndex);
        }
      } else {
        setTimeout(() => messagesListRef.current?.scrollToEnd(), 1000);
      }
    }
  }, [messages, scrollToMessageTimestamp, messagesLoaded]);

  const rateLimitedScrollToEnd = useCallback(() => {
    const now = Date.now();
    if (now - lastScrollTime.current >= 500) {
      messagesListRef.current?.scrollToEnd({ animated: false });
      lastScrollTime.current = now;
    }
  }, []);

  useEffect(() => {
    if (onNewMessage) {
      onNewMessage((message) => {
        if (messagesListRef.current) {
          handleNewMessage(message, messagesListRef.current);
          if (!message.complete) {
            rateLimitedScrollToEnd();
          }
        }
      });
    }
  }, [onNewMessage, handleNewMessage, rateLimitedScrollToEnd]);

  const handleSendMessage = useCallback(
    (text: string) => {
      sendConversationMessage(text);
      if (sendMessage) {
        sendMessage(text, `${messages.length + 1}`);
      }
      setTimeout(() => messagesListRef.current?.scrollToEnd(), 500);
    },
    [sendConversationMessage, sendMessage, messages.length]
  );

  const messagesListProps: MessagesListProps = {
    messages,
    inverted,
    primaryColor: mergedStyleCustomization.primaryColor as string,
    assistantColor: mergedStyleCustomization.assistantColor as string,
    primaryTextColor: mergedStyleCustomization.primaryTextColor as string,
    assistantTextColor: mergedStyleCustomization.assistantTextColor as string,
    dayLabelColor: mergedStyleCustomization.dayLabelColor as string,
    debugClicked: setSelectedMessage,
    showDebug,
    messagesAdditionalStyles:
      mergedStyleCustomization?.messagesAdditionalStyles,
    botIcon: mergedStyleCustomization?.botIcon,
    useBotIcon: mergedStyleCustomization?.useBotIcon ?? true,
    messageMaxWidth: mergedStyleCustomization?.messageMaxWidth,
  };

  if (mergedStyleCustomization?.botIconSize !== undefined) {
    messagesListProps.botIconSize = mergedStyleCustomization.botIconSize;
  }

  const sendMessageFormStyle: SendMessageFormProps['style'] = {
    sendMessageStyles: mergedStyleCustomization?.sendMessageStyles,
    inputFieldStyles: mergedStyleCustomization?.inputFieldStyles,
    sendIconStyles: mergedStyleCustomization?.sendIconStyles,
    spinnerStyles: mergedStyleCustomization?.spinnerStyles,
    color: mergedStyleCustomization?.primaryColor,
    iconVariant: mergedStyleCustomization?.iconVariant as
      | 'light'
      | 'solid'
      | undefined,
  };

  return (
    <TamaguiProvider config={tamaguiConfig}>
      <View
        style={[
          styles.globalContainer,
          mergedStyleCustomization?.centerChat && { justifyContent: 'center' },
        ]}
      >
        <View
          style={[
            styles.messagesAndFormContainer,
            mergedStyleCustomization?.messagesAndFormContainerStyle,
          ]}
        >
          <View
            style={[
              styles.messagesContainer,
              mergedStyleCustomization?.messagesContainerStyle,
            ]}
          >
            {!messagesLoaded && <ChatLoader style={styles.chatLoader} />}
            {messagesLoaded && messages.length > 0 && (
              <MessagesList ref={messagesListRef} {...messagesListProps} />
            )}
          </View>
          {(isConnected || !wsUrl) && (
            <SendMessageForm
              apiBaseUrl={apiBaseUrl}
              audioUrl={`${apiBaseUrl}/${anonymous ? 'public' : `org/${organizationId}`
                }/audio`}
              preferencesUrl={`${apiBaseUrl}/${anonymous ? 'public' : `org/${organizationId}`
                }/preferences`}
              style={sendMessageFormStyle}
              isLoading={recevingNewMessage}
              translations={
                translations
              }
              audioSent={() => {
                messagesListRef.current?.scrollToEnd();
                setRecevingNewMessage(true);
              }}
              sendMessage={handleSendMessage}
              headers={headers}
              onLanguageChange={handleLanguageChange}
            />
          )}
        </View>
        {selectedMessage?.debug && (
          <Debug
            debug={selectedMessage.debug}
            onClose={() => setSelectedMessage(null)}
            backgroundColor={mergedStyleCustomization?.backgroundColor}
            style={mergedStyleCustomization?.debugStyle}
          />
        )}
      </View>
    </TamaguiProvider>
  );
};

export default ConversationAi;

export type LanguageType = 'en' | 'es' | 'fr' | 'de' | 'it';
