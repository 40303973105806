import type { HistoryInterface } from '../interfaces/History.interface';

// Fetch conversations and filter by conversationId if provided
export const conversationHistory = async (
  apiUrl: string,
  authToken: string
): Promise<HistoryInterface[]> => {
  const res = await fetch(apiUrl, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  if (!res.ok) {
    throw new Error(`HTTP error! status: ${res.status}`);
  }
  return res.json();
};
