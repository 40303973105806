import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { View } from 'tamagui';
import React from 'react';

export const AudioRecorderComponent: React.FC<{
  handleAudioSend: (formData: FormData) => void;
}> = ({ handleAudioSend }) => {
  const recorderControls = useAudioRecorder();
  const addAudioElement = (blob: Blob) => {
    const file = new File([blob], 'audio.webm', { type: blob.type, lastModified: Date.now() });
    const formData = new FormData();
    formData.append('file', file);
    handleAudioSend(formData);
  };

  return (
    <View>
      <AudioRecorder
        onRecordingComplete={(blob) => addAudioElement(blob)}
        recorderControls={recorderControls}
        downloadFileExtension="webm"
        showVisualizer={true}
      />
    </View>
  );
};
