import { useState, useCallback, useEffect, useMemo } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const useAuth = () => {
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [host, setHost] = useState<string | null>(null);
  const [authLoaded, setAuthLoaded] = useState(false);
  const [anonymousSessionId, setAnonymousSessionId] = useState<
    string | undefined
  >();
  const [headers, setHeaders] = useState<Headers>(new Headers());

  useEffect(() => {
    const loadStoredData = async () => {
      try {
        const storedSessionId = await AsyncStorage.getItem(
          'anonymousSessionId'
        );
        if (storedSessionId) {
          setAnonymousSessionId(storedSessionId);
        }
        setAuthLoaded(true);
      } catch (error) {
        console.error('Error loading stored data:', error);
      }
    };
    loadStoredData();
  }, []);

  const updateAuthToken = useCallback(async (token: string) => {
    try {
      setAuthToken(token);
      await AsyncStorage.removeItem('anonymousSessionId');
      setAnonymousSessionId(undefined);
    } catch (error) {
      console.error('Error updating auth data:', error);
    }
  }, []);

  const updateHost = useCallback((newHost: string) => {
    setHost(newHost);
  }, []);

  const updateAnonymousSession = useCallback(async (sessionId: string) => {
    try {
      await AsyncStorage.setItem('anonymousSessionId', sessionId);
      setAnonymousSessionId(sessionId);
    } catch (error) {
      console.error('Error saving anonymous session ID:', error);
    }
  }, []);

  useEffect(() => {
    const newHeaders = new Headers({
      'Content-Type': 'application/json',
    });

    if (authToken) {
      newHeaders.set('Authorization', `Bearer ${authToken}`);
    }
    if (anonymousSessionId) {
      newHeaders.set('X-account-id', anonymousSessionId);
    }
    if (host) {
      newHeaders.set('X-service-domain', host);
    }

    setHeaders(newHeaders);
  }, [authToken, host, anonymousSessionId]);

  return useMemo(
    () => ({
      anonymousSessionId,
      updateAnonymousSession,
      headers,
      updateHost,
      updateAuthToken,
      authLoaded
    }),
    [updateAnonymousSession, headers, updateHost, anonymousSessionId, authLoaded]
  );
};
