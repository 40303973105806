import type { DebugInfo } from '../interfaces/DebugInfo.interface';
import type { HistoryInterface } from '../interfaces/History.interface';
import type { MessageInterface } from '../interfaces/Message.interface';

export function textToImessage({
  text,
  timestamp,
  id,
  role,
  debug,
}: {
  text: string;
  timestamp: string;
  id: string;
  name: string;
  role: 'user' | 'assistant';
  debug?: DebugInfo;
}): MessageInterface {
  return {
    id,
    text,
    isUser: role === 'user',
    createdAt: new Date(timestamp),
    debug,
    scrollToEndFix: false,
  };
}

export const conversationHistoryToChatMessages = (
  history: HistoryInterface[]
): MessageInterface[] => {
  return history.map((item, index) => ({
    id: `${index}`,
    text: item.content,
    isUser: item.role === 'user',
    createdAt: new Date(item.timestamp),
    debug: item.debug,
    attachments: item.images?.map((image) => ({
      type: 'image',
      url: image.url,
      label: image.label,
    })),
    scrollToEndFix: false,
  }));
};

// {  role: 'user' | 'assistant';
// content: string;
// timestamp: string; // '2024-02-14T09:00:45Z';
// audio_file_url: string;
// debug: {
//   context: string;
//   queries: {
//     changed: boolean;
//     used_query: string;
//     original_query: string;
//   };
//   timings: {
//     oai_query_millis_d2: number;
//     query_preprocess_millis: number;
//     vect_db_query_millis_d2: number;
//     token_limit_check_millis_d2: number;
//   };
//   sections?: DebugInfo;
// };
