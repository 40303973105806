import React from 'react';
import { Text, View } from 'tamagui';
import { StyleSheet, Image, TouchableOpacity } from 'react-native';
import { format } from 'date-fns';
import type { MessageInterface } from '../interfaces/Message.interface';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import chatgptImage from '../assets/chatgpt.jpg';

const styles = StyleSheet.create({
  message: {
    borderRadius: 15,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  userMessage: {
    borderTopRightRadius: 3,
  },
  assistantMessage: {
    borderBottomLeftRadius: 3,
  },
  timestamp: {
    fontSize: 10,
    marginTop: 5,
  },
  day: {
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 600,
    marginTop: 10,
    marginBottom: 10,
  },
});

export const Message = ({
  message: {
    text,
    isUser,
    createdAt,
    showDaylabel,
    audioUrl,
    attachments,
    debug,
    scrollToEndFix,
  },
  messageMaxWidth,
  primaryColor,
  assistantColor,
  primaryTextColor,
  assistantTextColor,
  dayLabelColor,
  messagesAdditionalStyles,
  botIcon = chatgptImage,
  useBotIcon,
  debugClicked,
  showDebug,
  botIconSize,
}: {
  message: MessageInterface;
  messageMaxWidth?: number;
  primaryColor?: string;
  assistantColor?: string;
  primaryTextColor?: string;
  assistantTextColor?: string;
  dayLabelColor: string;
  messagesAdditionalStyles?: object;
  botIcon?: React.ReactNode;
  useBotIcon?: boolean;
  debugClicked: () => void;
  showDebug: boolean;
  botIconSize: number;
}) => {
  const color = isUser ? primaryTextColor : assistantTextColor;
  const backgroundColor = isUser ? primaryColor : assistantColor;

  if (scrollToEndFix) {
    return <View></View>;
  }
  return (
    <>
      {showDaylabel && (
        <Text style={styles.day} color={dayLabelColor}>
          {format(createdAt, 'MMM d, yyyy')}
        </Text>
      )}
      <View
        style={{
          flex: 1,
          margin: 10,
        }}
      >
        <View
          style={{
            marginLeft: isUser ? 'auto' : 'inherit',
            marginRight: !isUser ? 'auto' : 'inherit',
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}
        >
          {!isUser && useBotIcon && botIcon && (
            <Image
              source={
                typeof botIcon === 'number'
                  ? botIcon
                  : { uri: botIcon as string }
              }
              style={{ width: botIconSize, height: botIconSize }}
            ></Image>
          )}

          <View
            style={{
              ...styles.message,
              ...(isUser ? styles.userMessage : styles.assistantMessage),
              maxWidth: messageMaxWidth,
              color,
              backgroundColor,
            }}
          >
            {text.split('\n').map((item, key) => {
              return (
                <Text style={messagesAdditionalStyles} key={key} color={color}>
                  {item.trim() === '' ? '\u00A0' : item}
                  {'\n'}
                </Text>
              );
            })}

            {/* <View>
              {text.split('\n').map((line, index) => (
                <Text
                  color={color}
                  style={messagesAdditionalStyles}
                  key={index}
                >
                  {line === '' ? 'aaa' : line}
                </Text>
              ))}
            </View> */}

            <Text
              color={color}
              textAlign={isUser ? 'right' : 'left'}
              style={styles.timestamp}
            >
              {format(createdAt, 'hh:mm a')}
            </Text>
            {audioUrl && (
              <>
                <audio controls autoPlay>
                  <source src={audioUrl} type="audio/mpeg" />
                </audio>
              </>
            )}
            {attachments &&
              attachments.map((attachment, index) => {
                if (attachment.type === 'image') {
                  return (
                    <View marginTop={16} justifyContent={'center'}>
                      <Image
                        source={{ uri: attachment.url }}
                        key={index}
                        style={{
                          width: '100%',
                          height: 200,
                        }}
                        resizeMode={'contain'}
                      ></Image>
                      <Text style={[styles.timestamp, { textAlign: 'center' }]}>
                        {attachment.label}
                      </Text>
                    </View>
                  );
                } else {
                  return null;
                }
              })}

            {showDebug && !isUser && debug && debug.sections && (
              <View style={{ alignSelf: 'end' }}>
                <TouchableOpacity
                  onPress={() => {
                    showDebug ? debugClicked() : null;
                  }}
                >
                  <FontAwesomeIcon icon={faCircleQuestion} />
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </View>
    </>
  );
};
